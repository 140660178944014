import React from 'react'

const BuloLogo = ({color = "#000000"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="200" height="37" viewBox="0 0 200 37" fill="none">
    <g clipPath="url(#clip0_301_6083)">
      <path d="M45.7962 0C45.7503 1.89744 45.8249 3.80599 45.6345 5.69008C45.1763 10.4069 42.8095 14.7554 39.0524 17.7832C38.7806 18.0134 38.4986 18.2281 38.1764 18.4905C44.3755 23.2341 46.4155 29.5459 45.6862 36.9433H0.0127381V36.1247C0.0127381 24.3693 0.0127381 12.6147 0.0127381 0.860838C0.0127381 0.573887 0.00458684 0.286951 0 0H45.7962ZM37.4699 29.5693C37.408 29.3791 37.3805 29.2746 37.3392 29.1756C37.2635 28.9909 37.1913 28.8052 37.0984 28.6284C36.1638 26.6322 34.6353 24.9525 32.7094 23.8048C30.7834 22.6572 28.5478 22.094 26.2895 22.1875C20.3266 22.1709 14.3523 22.1875 8.38364 22.1875C8.14627 22.1875 7.90893 22.2153 7.69564 22.2287V29.5693H37.4699ZM7.67618 14.7335C7.86768 14.748 8.02012 14.7713 8.17493 14.7713C14.3465 14.7713 20.5192 14.7635 26.6908 14.7713C29.3545 14.8079 31.9439 13.9191 33.9909 12.2655C35.599 11.013 36.8049 9.34098 37.4666 7.44628H7.67618V14.7335Z" fill={color} />
      <path d="M200 0C199.994 5.85358 200 11.7072 199.977 17.5607C199.97 19.6739 200.014 21.805 199.846 23.9137C199.681 25.7783 199.126 27.5908 198.217 29.2415C197.308 30.8922 196.062 32.3466 194.556 33.5166C191.879 35.7994 188.424 37.0364 184.86 36.9878C174.838 36.9878 164.817 36.9878 154.796 36.9878H154.212V36.3293C154.212 29.0177 154.193 21.7059 154.223 14.3943C154.301 10.8779 155.677 7.50531 158.1 4.88881C160.523 2.27231 163.833 0.585456 167.43 0.13458C167.582 0.103484 167.73 0.0584192 167.873 0H200ZM161.836 29.5615C162.116 29.5738 162.315 29.5915 162.516 29.5915C169.917 29.5915 177.318 29.5915 184.72 29.5915C185.106 29.6006 185.493 29.5761 185.875 29.5182C187.717 29.2583 189.397 28.3493 190.593 26.9647C191.788 25.5802 192.416 23.817 192.357 22.0118C192.363 17.3739 192.363 12.7356 192.357 8.09691C192.357 7.88226 192.336 7.6687 192.325 7.42958C192.076 7.41734 191.875 7.40066 191.675 7.40066C184.297 7.40066 176.919 7.38731 169.543 7.40066C168.522 7.3789 167.507 7.55856 166.56 7.9287C165.613 8.29883 164.753 8.85176 164.033 9.55391C163.313 10.2561 162.747 11.0928 162.371 12.0134C161.994 12.934 161.814 13.9192 161.842 14.9092C161.83 17.8911 161.842 20.8729 161.842 23.8548L161.836 29.5615Z" fill={color} />
      <path d="M59.0628 0C59.0559 1.08218 59.0432 2.16437 59.0432 3.24656C59.0432 11.729 59.0432 20.2122 59.0432 28.6962V29.5927H59.7966C67.108 29.5927 74.4191 29.5927 81.7298 29.5927C82.7683 29.6315 83.8039 29.4619 84.7717 29.0945C85.7395 28.727 86.6186 28.1697 87.354 27.4574C88.0894 26.745 88.6651 25.8931 89.0452 24.9549C89.4254 24.0167 89.6016 23.0125 89.5629 22.0051C89.5629 14.6697 89.5583 7.33543 89.5492 0.00224018H97.2046C97.2046 7.07147 97.1679 14.1418 97.2046 21.211C97.3648 23.8666 96.8021 26.5163 95.5729 28.8942C92.627 34.0026 88.2224 36.9455 82.0657 36.9755C72.0435 37.0245 62.0202 36.99 51.9979 36.9889C51.8397 36.9889 51.6826 36.9766 51.4246 36.9655V36.1247C51.4246 24.3708 51.4246 12.6184 51.4246 0.867525C51.4246 0.580574 51.4165 0.293638 51.4131 0.00668659L59.0628 0Z" fill={color} />
      <path d="M110.458 0C110.451 0.264707 110.437 0.529402 110.437 0.794108C110.437 10.0959 110.437 19.3974 110.437 28.6985V29.5671H148.537V36.9433H102.804V0H110.458Z" fill={color} />
    </g>
    <defs>
      <clipPath id="clip0_301_6083">
        <rect width="200" height="37" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BuloLogo;