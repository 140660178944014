import { StaticImage } from 'gatsby-plugin-image'
import { t } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Body3 from '../../../tags/body3'
import H1 from '../../../tags/h1'
import ServiceTitle from '../../../tags/service.title'

const WellnessGraph = () => {
  
  const {t} = useTranslation();
  return (
    <article className="w-full lg:max-w-7xl mx-auto px-4 md:px-page-x-sm lg:px-page-x-s py-page-y">
      <div className="w-full flex flex-col md:flex-row justify-between lg:space-x-[95px]">
        <TextTypography text={t}/>
        <GraphIamge />
      </div>
    </article>
  )
}
const TextTypography = ({text}) => (
  <div className="flex-1">
    <ServiceTitle>{text("wellness.graph.title")}</ServiceTitle>
    <Body3 className="mt-8 whitespace-pre-wrap">
    {text("wellness.graph.desc")}
    </Body3>
  </div>
)
const GraphIamge = () => (
  <div className="w-full md:w-auto flex justify-end md:block">
    <StaticImage
      src="../../../../images/service/wellness_graph.png"
      quality="100"
      className="w-[510px] md:w-[306px] lg:w-[472px]"
      alt="Wellness Graph"
    />
  </div>
)
export default WellnessGraph