import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import { useTranslation } from 'react-i18next';
import Body3 from '../../../tags/body3';
import H1 from '../../../tags/h1';
import ServiceTitle from '../../../tags/service.title';

const WellnessHistory = () => {
  
  const {t} = useTranslation();
  return (
    <article className="w-full lg:max-w-7xl mx-auto py-page-y px-4 md:px-page-x-sm flex flex-col items-center">
      <HistoryTitle text={t}/>
      <Body3 className="mt-8 whitespace-pre-wrap text-center">
        {t("wellness.history.desc")}
      </Body3>
      <HistoryImage />
    </article>
  )
}
const HistoryTitle = ({text}) => (
  <ServiceTitle>{text("wellness.history.title")}</ServiceTitle>
)
const HistoryImage = () => (
  <StaticImage
    src="../../../../images/service/wellness_history.png"
    quality={100}
    alt="BULO Wellness History"
    className="mt-8 object-contain"
    imgStyle={{objectFit : 'contain'}}
  />
)
export default WellnessHistory;