import React from 'react'

const WellnessVersion = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="11" viewBox="0 0 21 11" fill="none">
    <path d="M0.405704 8.70525L5.8057 3.80024V2.73524L5.31071 2.24024H2.88071L2.3857 2.73524V3.80024H0.345703V2.12024L1.96571 0.500244H6.2257L7.8457 2.12024V4.40024L2.52071 9.14024V9.29024H7.9057V11.0002H0.405704V8.70525Z" fill="#5F42FB"/>
    <path d="M9.03399 10.3552V9.39524L9.67899 8.75024H10.774L11.404 9.41024V10.3402L10.774 11.0002H9.67899L9.03399 10.3552Z" fill="#5F42FB"/>
    <path d="M12.5402 9.26024V2.24024L14.2802 0.500244H18.9302L20.6702 2.24024V9.26024L18.9302 11.0002H14.2802L12.5402 9.26024ZM17.9702 9.26024L18.6302 8.60024V2.90024L17.9702 2.24024H15.2402L14.5802 2.90024V8.60024L15.2402 9.26024H17.9702Z" fill="#5F42FB"/>
  </svg>  
)

export default WellnessVersion;