import React from 'react'
import WellnessCalculation from '../../components/pages/service/wellness/calculation.wellness'
import WellnessExercise2 from '../../components/pages/service/wellness/exercise2.wellness'
import WellnessExercise from '../../components/pages/service/wellness/exerise.wellness'
import WellnessGraph from '../../components/pages/service/wellness/graph.app'
import WellnessHistory from '../../components/pages/service/wellness/history.wellness'
import WellnessHistory2 from '../../components/pages/service/wellness/history2.wellness'
import WellnessHowToUse from '../../components/pages/service/wellness/howto.wellness'
import WellnessImprovement from '../../components/pages/service/wellness/improvement.wellness'
import IntroWellness from '../../components/pages/service/wellness/intro.wellness'
import BuloMainArticle from '../../components/pages/service/wellness/main.article'
import WellnessMeasure from '../../components/pages/service/wellness/measure.wellness'
import WellnessReportSharing from '../../components/pages/service/wellness/report.share'
import Main from '../../layout/main'

const Wellness = (props) => {
  
  return (
    <Main title="웰니스 서비스" {...props}>
      <BuloMainArticle />
      <IntroWellness />
      <WellnessGraph />
      <WellnessHistory />
      <WellnessExercise />
      <WellnessCalculation />
      <WellnessImprovement />
      <WellnessReportSharing />
      <WellnessMeasure />
      <WellnessExercise2 />
      <WellnessHistory2 />
      <WellnessHowToUse />
    </Main>
  )
}

export default Wellness