import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Body2 from '../../../tags/body2'
import Body3 from '../../../tags/body3'
import ServiceTitle from '../../../tags/service.title'

const WellnessExercise2 = () => (
  <article className="w-full lg:max-w-7xl mx-auto px-4 md:px-page-x-sm lg:px-page-x-s py-page-y space-y-10">
    <ExerciseTypograph />
    <ExerciseImage />
  </article>
)

const ExerciseTypograph = () => {
  const {t} = useTranslation();
  return (
    <section className="flex flex-1 flex-col items-end">
      <ServiceTitle>Exercise</ServiceTitle>
      <ExerciseSub text={t("wellness.exercise2.title")}/>
      <ExerciseDetail text={t("wellness.exercise2.desc")}/>
    </section>
  )
}
const ExerciseImage = () => (
  <section className="w-full flex flex-1 justify-center">
    <StaticImage
      src="../../../../images/service/wellness_exercise2.png"
      quality={100}
      alt="고객 맞춤형 운동법"
      className="object-contain"
    />
  </section>
)
const ExerciseSub = ({text}) => (
  <Body2 className="mt-8">{text}</Body2>
)
const ExerciseDetail = ({text}) => (
  <Body3 className="mt-6 text-right whitespace-pre-line">
    {text}
  </Body3>
)
export default WellnessExercise2