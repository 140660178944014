import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import { useTranslation } from 'react-i18next';
import Body3 from '../../../tags/body3';
import ServiceTitle from '../../../tags/service.title';

const WellnessCalculation = () => {
  
  const {t} = useTranslation();
  return (
    <article className="w-full lg:max-w-7xl mx-auto px-4 md:px-page-x-sm lg:px-page-x-s py-page-y flex flex-col items-center">
      <CalculationTitle text={t} />
      <CalculationDetail text={t} />
      <CalculationImage />
    </article>
  )
}
const CalculationTitle = ({text}) => (
  <ServiceTitle>{text("wellness.calculation.title")}</ServiceTitle>
)
const CalculationDetail = ({text}) => (
  <Body3 className="mt-8 whitespace-pre-line text-center">
    {text("wellness.calculation.desc")}
  </Body3>
)
const CalculationImage = () => (
  <StaticImage
    src="../../../../images/service/wellness_calculation.png"
    alt="Bulo Wellness Calculation"
    quality={100}
    className="mt-10"
  />
)
export default WellnessCalculation;