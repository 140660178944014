import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import { useTranslation } from 'react-i18next';
import Body2 from '../../../tags/body2';
import Body3 from '../../../tags/body3';
import ServiceTitle from '../../../tags/service.title';

const WellnessHistory2 = () => (
  <article className="w-full lg:max-w-7xl mx-auto px-4 md:px-page-x-sm lg:px-page-x-s py-page-y flex flex-col md:flex-row md:justify-between space-x-0">
    <HistoryTypograph />
    <HistoryImage />
  </article>
)
const HistoryTypograph = () => {
  const {t} = useTranslation();
  return (
    <section className="flex flex-col flex-1">
      <ServiceTitle>History</ServiceTitle>
      <HistorySub text={t("wellness.history2.title")}/>
      <HistoryDetail text={t("wellness.history2.desc")}/>
    </section>
  )
}
const HistoryImage = () => (
  <section className="flex-1 mt-8 md:mt-0 flex justify-center md:justify-start">
    <StaticImage 
      src="../../../../images/service/wellness_history2.png"
      quality={100}
      alt="Bulo auto save"
      className="object-contain" 
    />
  </section>
)
const HistorySub = ({text}) => (
  <Body2 className="mt-8">{text}</Body2>
)
const HistoryDetail = ({text}) => (
  <Body3 className="mt-6 whitespace-pre-line">
    {text}
  </Body3>
)
export default WellnessHistory2;