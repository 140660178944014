import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import { useTranslation } from 'react-i18next';
import Body3 from '../../../tags/body3';
import ServiceTitle from '../../../tags/service.title';

const WellnessImprovement = () => {
  
  const {t} = useTranslation();
  return (
    <article className="w-full bg-wellness">
      <div className="lg:max-w-7xl mx-auto py-page-y px-4 md:px-page-x-sm lg:px-page-x-s ">
        <div className="flex flex-col md:flex-row space-y-5 md:space-x-5">
          <TextTypograph text={t}/>
          <ImprovementIamges />
        </div>
      </div>
    </article>
  )
}
const TextTypograph = ({text}) => (
  <div className="flex flex-col flex-1">
    <ServiceTitle>{text("wellness.improvement.title")}</ServiceTitle>
    <Body3 className="mt-8 whitespace-pre-line">
      {text("wellness.improvement.desc")}
    </Body3>
  </div>
)
const ImprovementIamges = () => (
  <div className="flex justify-center md:justify-end">
    <StaticImage
      src="../../../../images/service/wellness_improvement.png"
      alt="Wellness Improvement1"
      quality={100}
      className="object-contain"
    />
  </div>
)
export default WellnessImprovement;