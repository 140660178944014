import React from 'react'
import { useTranslation } from 'react-i18next';
import BuloLogo from '../../../icons/bulo.logo';
import ServiceWrapLine from '../../../icons/service.wrap.line';
import ServiceIntroLine from '../../../images/service.intro';
import WellnessVersion from '../../../images/wellness.version';
import Body2 from '../../../tags/body2';
import Body3 from '../../../tags/body3';
const IntroWellness = () => {
  
  const {t} = useTranslation();
  return (
    <article className="relative w-full lg:max-w-7xl mx-auto  px-4 md:px-page-x-sm lg:px-page-x-s h-[332px]">
      <IntroLine />
      <div className="w-full h-full pb-12 flex flex-col px-4 md:px-page-x-sm lg:px-0 justify-center items-center">
        <IntroLogo/>
        <Body2 className="mt-7">{t("wellness.intro.title")}</Body2>
        <Body3 className="mt-3 text-center whitespace-pre-line">{t("wellness.intro.desc")}</Body3>
      </div>
    </article>
  )
}

const IntroLine = () => (
  <div className="absolute px-4 md:px-page-x-sm lg:px-page-x-s left-0 top-0 w-full h-full">
    <ServiceWrapLine />
  </div>
)
const IntroLogo = () => (
  <div className="flex flex-col items-end space-y-2">
    <BuloLogo color="#5F42FB"/>
    <WellnessVersion />
  </div>
)
export default IntroWellness;