import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef } from 'react'
import {useTranslation} from 'react-i18next';
import { Korean } from '../../../../../i18n';
import WellnessActionButton from '../../../button/service/action';
import BuloLogo from '../../../icons/bulo.logo';

const ImageSize = 1280;
const BuloDist = 1020;
const BuloMainArticle = ({}) => {
  const layoutRef = useRef();
  const bgPosition = useRef(0);
  const {t} = useTranslation();
  useEffect(()=>{
    if(!layoutRef.current) return;
    let {width} = layoutRef.current.getBoundingClientRect();

    layoutRef.current.style.backgroundPositionX = width >= ImageSize 
                          ? 'center'
                          : `-${BuloDist - width}px`;
  }, [layoutRef.current])
  return (
    <article className="relative w-full lg:max-w-7xl mx-auto  h-[640px] flex justify-start items-center text-[44px] font-thin text-white bg-[url('../images/service/bulo_service.png')] bg-no-repeat"
      style={{
        backgroundPositionX : bgPosition.current
      }}
      ref={layoutRef}
    >
      <LeftWhiteLayer />
      <div className="px-4 md:pl-page-x-sm lg:pl-page-x-s flex flex-col items-start z-10">
        <BuloLogo color="#5F42FB"/>
        <ServiceTitle className="mt-8">{t("wellness.main.title")}</ServiceTitle>
        <p className="mt-4 max-w-[250px] md:max-w-full text-base md:text-2xl leading-6 md:leading-[34px] text-gray-080">{t("wellness.main.subTitle")}</p>
        <PurchaseButton text={t} />
        <DownloadApps />
      </div>
      <RightWhiteLayer />
    </article>
  )
}
const ServiceTitle = ({children}) => (
  <h1 className="mt-8 text-2xl leading-8 font-bold md:text-[32px] md:leading-9 text-gray-080">{children}</h1>
)
const PurchaseButton = ({text}) => (
  <WellnessActionButton 
    link="https://smartstore.naver.com/breathings/products/5534694014"
    title={text("wellness.main.purchase")}
  />
)

const DownloadApps = () => {
  
  const {i18n : {language}} = useTranslation();
  return (
    <div className="mt-7 w-full flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
      <a href="https://play.google.com/store/apps/details?id=com.breathings.bulo.v2" target="_blank">
        {
          language === Korean 
          ? 
            <StaticImage
              src={"../../../../images/buttonGooglePlay.png"}
              quality="100"
              alt="GooglePlay"
            />
          : 
            <StaticImage
              src={"../../../../images/buttonGooglePlay_en.png"}
              quality="100"
              alt="GooglePlay"
            />
        }
      </a>
      <a href="https://itunes.apple.com/app/id1603428779" target="_blank">
        {
          language === Korean 
          ? 
            <StaticImage
              src={"../../../../images/buttonAppleStore.png"}
              quality="100"
              alt="AppStore"
            />
          : 
            <StaticImage
              src={"../../../../images/buttonAppleStore_en.png"}
              quality="100"
              alt="AppStore"
            />
        }
      </a>
    </div>
  )
}
const LeftWhiteLayer = () => (
  <div className="absolute hidden lg:block left-0 top-0 h-full w-full lg:w-4/12 bg-gradient-to-r from-white to-transparent">
  </div>
)
const RightWhiteLayer = () => (
  <div className="absolute hidden lg:block right-0 top-0 h-full w-full lg:w-4/12 bg-gradient-to-l from-white to-transparent">
  </div>
)
export default BuloMainArticle;