import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Body2 from '../../../tags/body2'
import Body3 from '../../../tags/body3'
import ServiceTitle from '../../../tags/service.title'

const WellnessMeasure = () => {
  
  const {t} = useTranslation();
  return (
    <article className="w-full lg:max-w-7xl mx-auto  px-4 md:px-page-x-sm lg:px-page-x-s py-page-y flex flex-col md:flex-row space-y-8 md:space-y-0">
      <MeasureTypograph text={t} />
      <MeasureImage />
    </article>
  )
}

const MeasureTypograph = ({text}) => (
  <section className="flex flex-1 flex-col">
    <MeasureTitle/>
    <MeasureSub title={text("wellness.measure.title")}/>
    <MeasureDetail desc={text("wellness.measure.desc")}/>
  </section>
)
const MeasureImage = () => (
  <section className="flex-1 flex justify-center md:justify-start">
    <StaticImage
      src="../../../../images/service/wellness_measure.png"
      quality={100}
      alt="Bulo Wellness Measure"
      className="object-contain"
    />
  </section>
)
const MeasureTitle = () => (
  <ServiceTitle>Measure</ServiceTitle>  
)
const MeasureSub = ({title}) => (
  <Body2 className="mt-8 whitespace-pre-line">
    {title}
  </Body2>
)
const MeasureDetail = ({desc}) => (
  <Body3 className="mt-6 whitespace-pre-line">
    {desc}
  </Body3>
)
export default WellnessMeasure;