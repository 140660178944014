import { graphql, useStaticQuery } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { useTranslation } from 'react-i18next'
import WellnessActionButton from '../../../button/service/action'
import Body3 from '../../../tags/body3'
import ServiceTitle from '../../../tags/service.title'

const WellnessHowToUse = () => (
  <article className="w-full lg:max-w-7xl mx-auto flex flex-col items-center px-4 md:px-page-x-sm lg:px-page-x-s py-page-y">
    <HowtoUseTypograph />
    <HowotUseSteps />
  </article>
)
const HowtoUseTypograph = () => {
  const {t} = useTranslation();
  return (
    <section className="w-full flex flex-col items-center">
      <ServiceTitle className="font-metropolis">How to Use</ServiceTitle>
      <WellnessActionButton link="https://youtu.be/P9LAu6YkzUY" title={t("wellness.howtouse.checkGuide")} />
    </section>
  )
}


export const imagesQuery = graphql`
query Images {
  allFile(filter: {absolutePath: {regex: "/images/service/wellness_howtouse_/d/"}}) {
    nodes {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
}
`
const HowotUseSteps = () => {
  const data = useStaticQuery(imagesQuery);
  const {t} = useTranslation();
  const images = data.allFile.nodes;
  return (
    <section className="mt-12 w-full flex flex-col md:flex-row md:justify-between items-center md:items-start space-y-8 md:space-y-0 md:space-x-5">
      <HowtoUseStepItem 
        step={1}
        source={images[0]}
        desc={t("wellness.howtouse.step01")}
      />
      <HowtoUseStepItem 
        step={2}
        source={images[1]}
        desc={t("wellness.howtouse.step02")}
      />
      <HowtoUseStepItem 
        step={3}
        source={images[2]}
        desc={t("wellness.howtouse.step03")}
      />
    </section>
  )
}

const HowtoUseStepItem = ({step, source, desc}) => {
  const image = getImage(source)
  return (
  <div className="flex md:flex-[33%] flex-col w-[358px] md:min-w-[203px] md:max-w-[307px]">
    <GatsbyImage 
      image={image}
      alt={`Bulo Use step ${step}`}
      className="object-contain md:w-full md:h-[176px]"
    />
    <span className="mt-8 text-base leading-4 text-main font-bold font-metropolis">Step 0{step}</span>
    <Body3 className="mt-6 whitespace-pre-line">
      {desc}
    </Body3>
  </div>
  )
}
export default WellnessHowToUse