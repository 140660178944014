import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import { useTranslation } from 'react-i18next';
import Body3 from '../../../tags/body3';
import ServiceTitle from '../../../tags/service.title';

const WellnessReportSharing = () => {
  
  const {t} = useTranslation();
  return (
    <article className="w-full lg:max-w-7xl mx-auto  pt-page-y px-4 md:px-page-x-sm lg:px-page-x-s flex flex-col items-center">
      <ReportSharingTitle text={t} />
      <ReportSharingDetail text={t} />
      <ReportSharingIamge />
    </article>
  )
}
const ReportSharingTitle = ({text}) => (
  <ServiceTitle>{text("wellness.report.title")}</ServiceTitle>
)
const ReportSharingDetail = ({text}) => (
  <Body3 className="mt-8 whitespace-pre-line text-center">
    {text("wellness.report.desc")}
  </Body3>
)
const ReportSharingIamge = () => (
  <section className="w-full flex justify-center mt-10 rounded-t-3xl bg-wellness">
    <StaticImage
      src="../../../../images/service/wellness_report_share.png"
      quality={100}
      alt="Bulo Wellness Report Sharing"
      className="object-contain"
    />
  </section>
)
export default WellnessReportSharing;