import React from 'react'

const ServiceWrapLine = ({}) => (
  <div className="relative h-[332px] w-full flex">
    <Line><div className="w-full h-full border-l-2 border-b-2 border-t-0 border-r-0 border-main rounded-bl-[50px]" /></Line>
    <Center />
    <Line><div className="w-full h-full border-r-2 border-b-2 border-t-0 border-l-0 border-main rounded-br-[50px]" /></Line>
  </div>
)
const Line = ({children}) => (
  <div className="flex-1 h-full pb-12">{children}</div>
)
const Center = () => (
  <div className="relative h-full w-48 flex items-end -z-10">
    <div className="absolute bottom-0 -left-[1px] h-[50px] w-[98px] border-t-2 border-r-2 border-main rounded-tr-[50px]" />
    <div className="absolute bottom-0 -right-[1px] h-[50px] w-[98px] border-t-2 border-l-2 border-main rounded-tl-[50px]" />
  </div>
)

export default ServiceWrapLine;